<template>
  <div>
    <!-- 智慧人资 -->
    <div class="bigbox">
     <!-- 智慧人资top -->
      <div class="zhrztop">
        <div class="indeximgtopcontent">
          <div class="indexlogo"></div>
          <div class="indexlist">
            <ul class="indexlistul">
              <li  @click="go('/home')"><span>首页</span></li>
              <li  class="indexli2">
                <span class="active2">产品解决方案</span>
                  <ul class="menu">
                    <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                    <li @click="go('/cpjjfa/zhcg')">智慧采购</li>
                    <span class="active" @click="go('/cpjjfa/zhrz')">智慧人资</span>
                    <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                    <li @click="go('/cpjjfa/bgxt')">办公协同</li>
                    <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                  </ul>
              </li>
              <li @click="go('/jszc')"><span>技术支持</span></li>
              <li @click="go('/yhal')"><span>用户案例</span></li>
              <li  @click="go('/gywm')"><span>关于我们</span></li>
            </ul>
        </div>
        <a href="http://www.kingislucky.cn:81/#/home" class="enbutton">
          <img src="../.././assets/enicon.png" alt="">
          <div>Translate</div>
        </a>
      </div>

        <div class="zhfwbutton"></div>
      </div>
      <!-- 行业痛点 -->
      <div class="hytd">
        <div class="hytdcontent">
          <div class="hytdcontenttitle">
            <div class="hxystitleback"></div>
            <div class="hxystitl-ti">行业痛点</div>
            <div class="hxystitleback2"></div>
          </div>
          <div class="hytdpng"></div>
        </div>
      </div>
      <!-- 建设原则 -->
      <div class="jsyz">
        <div class="jsyzcontent">
          <div class="jsyzcontenttitle">
            <div class="hxystitleback"></div>
            <div class="hxystitl-ti">建设原则</div>
            <div class="hxystitleback2"></div>
          </div>
          <div class="jsyzpng">
            <img src="../.././assets/zhrz_jsyzpng.png" alt="">
          </div>
        </div>
      </div>
      <!-- 产品方案 -->
      <div class="cpfa">
        <div class="cpfacontent">
          <div class="cpfacontenttitle">
            <div class="hxystitleback"></div>
            <div class="hxystitl-ti">行业痛点</div>
          <div class="hxystitleback2"></div>
          </div>
          <div class="cpfapng"></div>
        </div>
      </div>
      <!-- 产品优势 -->
      <div class="cpys">
        <div class="cpyscontent">
          <div class="cpyscontenttitle">
            <div class="hxystitleback"></div>
            <div class="hxystitl-ti">产品优势</div>
          <div class="hxystitleback2"></div>
          </div>
          <div class="cpyspng">
            <div class="cpyspngevery"><img src="../.././assets/zhrz_cpyspng1.png" alt=""></div>
            <div class="cpyspngevery"><img src="../.././assets/zhrz_cpyspng2.png" alt=""></div>
            <div class="cpyspngevery"><img src="../.././assets/zhrz_cpyspng3.png" alt=""></div>
          </div>
        </div>
      </div>
      <!--  -->
      <!-- <zi-xun></zi-xun> -->
      <!--  -->
      <tabar-bottom></tabar-bottom>
    </div>
  </div>
</template>

<script>
import TabarBottom from '../../components/TabarBottom.vue'
import ZiXun from '../../components/ZiXun.vue'
  export default {
    methods:{
       go(path){
        this.$router.push({
          path:path
        })
      }
    },
  components: { ZiXun, TabarBottom },
    name:'zhrz'

  }
</script>

<style lang="scss" scoped>
.enbutton{
  cursor: pointer;
  position: absolute;
  z-index: 9999;
  right: 5%;
  top: 42px;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  width: 102px;
  height: 28px;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
// 产品优势
.cpys{
  // width: 1903px;
  height: 572px;
  margin: 0 auto;
  background: #F8F8F8;
  .cpyscontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
    .cpyscontenttitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  padding-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.cpyspng{
  width: 1200px;
  height: 280px;
  display: flex;
  justify-content: space-between;
  div{
    width: 386px;
    height: 280px;
  }
}
  }
}
// 产品方案
.cpfa{
  // width: 1903px;
  height: 866px;
  margin: 0 auto;
  .cpfacontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
         .cpfacontenttitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.cpfapng{
  width: 1200px;
  height: 564px;
  background: url('../.././assets/zhrz_cpfapng.png') no-repeat center;
  background-size: 1200px 564px;
}
  }
}
// 建设原则
.jsyz{
  // width: 1903px;
  height: 674px;
  background: url('../.././assets/zhrz_jsyzback.png') no-repeat center center;
  background-size: 100%;
  margin: 0 auto;
  .jsyzcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
       .jsyzcontenttitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  padding-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
  }
  .jsyzpng{
    width: 1200px;
    height: 380px;
    img{
      width: inherit;
      height: inherit;
    }
  }
}
// 行业痛点
.hytd{
  // width: 1903px;
  height: 790px;
  background: #FFFFFF;
  margin: 0 auto;
  .hytdcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
      .hytdcontenttitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.hytdpng{
  width: 1200px;
  height: 520.14px;
  background: url('../.././assets/zhrz_hytd.png') no-repeat center center;
  background-size: 1200px 520.14px;
}
  }
}
// 智慧人资top
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhrztop{
  // width: 1903px;
  height: 500px;
  background: url('../.././assets/zhrzback.png') no-repeat center 0;
  margin: 0 auto;
   padding-top: 34px;
  .indexlogo{

  width: 220px;
  height: 52px;
  background: url('../.././assets/logo.png') no-repeat center center;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
  width: 632px;
  text-align: center;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      // text-align: center;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  text-align: left;
  left: 16px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
